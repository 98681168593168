<template>
  <v-app>
    <TheHeader />
    <v-main>
      <router-view />
    </v-main>
    <TheFooter />
    <CookieConsent />
  </v-app>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import CookieConsent from "@/components/CookieConsent.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter,
    CookieConsent
  }
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Inter");
html,
body {
  font-family: "Inter", sans-serif;

  @media only screen and (max-width: 1500px) {
    font-size: 12px !important;
  }
}

#app {
  font-family: "Inter", sans-serif;
}
</style>
