<template>
  <div v-if="!hideCookie" class="cookie-consent white--text">
    <div class="text">
      We use cookies to give you the best browsing experience. By using our website, you agree to
      our
      <v-btn class="pl-1 pr-0" color="white" plain :to="{ name: link.name, hash: `#${link.hash}` }">{{
        link.name
      }}</v-btn
      >.
    </div>
    <div>
      <v-btn class="mr-4 px-8" @click="agreeCookie" rounded>Agree</v-btn>
    </div>
  </div>
</template>

<script>
const CookieLocalStorageKey = "cookie-consent";
import { cookieConsent } from "@/router";

export default {
  data() {
    return {
      link: cookieConsent,
      hideCookie: localStorage.getItem(CookieLocalStorageKey)
    };
  },
  methods: {
    agreeCookie() {
      this.hideCookie = "hide";
      localStorage.setItem(CookieLocalStorageKey, "hide");
    },
    refuseCookie() {
      this.hideCookie = "hide";
    }
  }
};
</script>

<style lang="scss">
.cookie-consent {
  display: flex;
  justify-content: space-between;
  padding: 0  toRem(50px);
  background-color: #58acf1;
  align-items: center;
  position: fixed;
  width: 100%;
  height:  toRem(72px);
  left: 0;
  bottom: 0;
  font-weight: 400;
  font-size:  toRem(16px);
  line-height: 1.5;

  .text {
    display: flex;
    justify-content: center;
    align-items: center;

    .v-btn {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  .v-btn {
    text-transform: capitalize;
    .v-btn__content {
      opacity: 1 !important;
    }
  }
}
</style>
>
