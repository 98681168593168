import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/HomeView.vue";

Vue.use(VueRouter);

export const header = [{
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/games",
    name: "Games",
    component: () => import("../views/GamesView.vue")
  },
  {
    path: "/service-items",
    name: "Service Items",
    component: () => import("../views/ServiceItems.vue")
  },
  {
    path: "/solution",
    name: "Solution",
    component: () => import("../views/SolutionView.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactView.vue")
  }
];

export const footer = [{
    path: "/terms",
    name: "Terms Of Use",
    hash: "terms-of-use",
    component: () => import("../views/TheTerms.vue")
  },
  {
    path: "/terms",
    name: "Privacy Policy",
    hash: "privacy-policy",
    component: () => import("../views/TheTerms.vue")
  }
];

export const cookieConsent = {
  path: "/terms",
  name: "Cookie Notice",
  hash: "cookie-notice",
  component: () => import("../views/TheTerms.vue")
};

export const routes = [...header, cookieConsent, cookieConsent, {
  path: "*",
  redirect: "/"
}];

const router = new VueRouter({
  routes
});

// scroll to top when router change
router.beforeEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    // behavior: 'smooth'
  })
  next();
})

export default router;
