<template>
  <v-footer class="footer" height="87">
    <v-row class="flex-nowrap" justify="center" align="center" no-gutters>
      <router-link class="d-flex align-center mr-16" to="/">
        <v-img max-width="208" contain src="@/assets/footer/tower king logo.png"></v-img>
      </router-link>
      <div class="py-4 text-center white--text">
        © {{ new Date().getFullYear() }} TowerKing Tech Limited All Rights Reserved.
      </div>
      <v-spacer></v-spacer>
      <v-btn
        v-for="link in links"
        :key="link.name"
        :to="{ path: link.path, hash: `#${link.hash}` }"
        color="white"
        plain
        rounded
        class="menu my-2"
      >
        {{ link.name }}
      </v-btn>
    </v-row>
  </v-footer>
</template>

<script>
import { footer } from "@/router";

export default {
  data: () => ({
    links: footer
  })
};
</script>

<style lang="scss">
.footer {
  .row {
    max-width: 75%;
  }

  &.v-footer {
    background: linear-gradient(0deg, #1182de, #1182de);
    justify-content: center;
    padding: 0;
  }

  .menu.v-btn {
    font-weight: 700;
    font-size:  toRem(20px);
    line-height:  toRem(24px);
    text-transform: capitalize;
  }
}
</style>
