<template>
  <v-app-bar class="header" height="80" color="#FFFFFF" app>
    <div class="container">
    <router-link class="d-flex align-center" to="/">
      <v-img width="210" contain src="@/assets/header/tower king logo.png" alt="logo" />
    </router-link>

    <v-tabs right>
      <v-tabs-slider color="transparent" />
      <v-tab v-for="menu in menus" :key="menu.path" :to="menu.path">
        {{ menu.name }}
      </v-tab>
    </v-tabs>

    </div>
  </v-app-bar>
</template>

<script>
import { header } from "@/router";
export default {
  data() {
    return {
      menus: header
    };
  }
};
</script>

<style lang="scss">
.header {
  padding: 0;
  box-shadow:  toRem(0px)  toRem(5px)  toRem(30px) rgba(0, 0, 0, 0.25) !important;

  .container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    max-width: 75%;
  }

  .v-tab {
    font-weight: 500;
    font-size:  toRem(25px);
    line-height:  toRem(24px);
    color: #101828 !important;
    text-transform: capitalize;
    letter-spacing: 0;

    &.v-tab--active {
      font-weight: 900;
      color: #175cd3 !important;
    }
  }
}
</style>
