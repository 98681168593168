<template>
  <v-carousel class="carousel" height="60vh" hide-delimiters :show-arrows="false">
    <v-carousel-item
      v-for="item in items"
      :key="item.title"
      :src="item.img"
    >
      <v-row :align="item.align" :justify="item.justify">
          <div class="text-section">
            <div class="subtitle" v-html="item.subtitle"></div>
            <div class="my-title" v-html="item.title"></div>
          </div>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  props: {
    /**
     * title:
     * subtitle:
     * image:
     */
    items: {
      type: Array,
      default: () => []
    }
  }
};
</script>

<style lang="scss">
.carousel {
  .row {
    height: 100%;
    padding: 5% 15%;
  }

  .text-section {
    max-width:  toRem(950px);
  }
  .subtitle {
    margin-bottom:  toRem(20px);
    font-weight: 400;
    font-size:  toRem(48px);
    line-height:  toRem(30px);
    color: #ffffff;
    text-shadow:  toRem(0px)  toRem(4px)  toRem(4px) rgba(0, 0, 0, 0.45);
  }

  .my-title {
    font-weight: 600;
    font-size:  toRem(60px);
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: #ffffff;
    text-shadow:  toRem(0px)  toRem(4px)  toRem(8px) rgba(0, 0, 0, 0.8);
  }
}
</style>
